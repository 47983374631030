import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'
import {ContentHero} from '../../styled-components/contentPages-sc'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  render() {
    return (
      <Layout>
        <ContentHero>
      </ContentHero>
        <section className="section">
          <div className="container">
            <div className="content">

            <div className="columns">
        <div className="column is-6">
              <h1>Contact us</h1>
              <form
                name="contact"
                method="post"
                action="/contact/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
              >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="contact" />
                <div hidden>
                  <label>
                    Don’t fill this out:{' '}
                    <input name="bot-field" onChange={this.handleChange} />
                  </label>
                </div>
                <div className="field">
                  <label className="label" htmlFor={'name'}>
                    Your name
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={'text'}
                      name={'name'}
                      onChange={this.handleChange}
                      id={'name'}
                      required={true}
                    />
                  </div>
                  </div>

                  <div className="field">
                  <label className="label" htmlFor={'number'}>
                    Your number
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={'text'}
                      name={'phone'}
                      onChange={this.handleChange}
                      id={'phone'}
                      required={true}
                    />
                  </div>
                  </div>
           
                <div className="field">
                  <label className="label" htmlFor={'email'}>
                    Email
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={'email'}
                      name={'email'}
                      onChange={this.handleChange}
                      id={'email'}
                      required={true}
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label" htmlFor={'option'}>
                    Property Type
                  </label>
                  <div className="control">
                    {/* <input
                      className="input"
                      type={'select'}
                      name={'project'}
                      onChange={this.handleChange}
                      id={'project'}
                      required={true}
                    /> */}
                    <select
                     className="input"
                     type={'select'}
                     name={'property'}
                     onChange={this.handleChange}
                     id={'property'}
                     required={true}
                    >
                      <option>-- Select one --</option>
                      <option value='residential'>Residential</option>
                      <option value='commercial'>Commercial</option>
                    </select>
                  </div>
                </div>

                <div className="field">
                  <label className="label" htmlFor={'message'}>
                    Message
                  </label>
                  <div className="control">
                    <textarea
                      className="textarea"
                      name={'message'}
                      onChange={this.handleChange}
                      id={'message'}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <button className="button is-link" type="submit">
                    Send
                  </button>
                </div>
              </form>
        </div>
        <div className="column is-6">
        <h1>
      Arreguin's Paint Contractor Inc.
      </h1>
      <p>  
      1318 Atlanta RD SE<br/>
      Marietta, GA 30060<br/>
      </p>
      <hr/>
      <p>
      Jose Arreguin - (770) 378-6726<br/>
      Juan Espinoza - (404) 886-8373
      </p>
      <hr />

      <h1>We service all Georgia</h1>
        </div>
        </div>

            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
